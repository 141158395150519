<template>
  <div v-if="textbausteinid != '0' && textbaustein == null" class="alert alert-danger">
    Der Textbaustein konnte nicht gefunden werden.

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div class="d-flex justify-content-between">
    <h1 v-if="textbausteinid == '0'">Neuer Textbaustein</h1>
    <h1 v-else>{{editTextbaustein.name}} Textbaustein bearbeiten</h1>
  </div>

  <form @submit.prevent="createOrUpdateTextbaustein">
    <TextbausteinContenBearbeiten :textbaustein="editTextbaustein" />

    <hr class="my-4">

    <div>
      <p><strong>Abschnitte</strong></p>
      
      <div v-for="(abschnitt, index) in editTextbaustein.abschnitte.sort((a: Textbaustein, b: Textbaustein) => a.position - b.position)" :key="index" class="row ms-5 mb-3 border p-3">
        <div v-if="editTextbaustein.abschnitte.length > 1" class="col-1 d-flex flex-column justify-content-start">
          <button type="button" class="btn btn-sm" @click="abschnittPosUp(index)" :disabled="abschnitt.position == 1"><font-awesome-icon icon="chevron-up" /></button>
          <button type="button" class="btn btn-sm" @click="abschnittPosDown(index)" :disabled="abschnitt.position == editTextbaustein.abschnitte.length"><font-awesome-icon icon="chevron-down" /></button>
        </div>
        <div v-else class="col-1"></div>

        <div class="col">
          <TextbausteinContenBearbeiten :textbaustein="abschnitt" :index="index" />
        </div>

        <div class="col-1">
          <button type="button" class="btn" @click="removeAbschnitt(index)"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>
      
      <button type="button" class="btn btn-primary" @click="addAbschnitt()"><font-awesome-icon icon="plus" /> Abschnitt hinzufügen</button>
    </div>

    <hr class="my-4">

    <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />
  
    <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
      <!-- Es ist ein Fehler beim Laden der Vorlagen aufgetreten. Bite probieren Sie es später erneut. -->
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
      <button type="submit" class="btn btn-primary">
        <span v-if="textbausteinid != '0'">Speichern</span>
        <span v-else>Erstellen</span>
      </button>
    </div>
  </form>

  <hr class="my-4">

  <button class="btn btn-danger" @click="deleteTextbaustein()"><font-awesome-icon icon="trash" /> Textbaustein löschen</button>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';
import { ActionTypes as VariablenActionTypes } from "@/store/modules/Variablen/actions";

import TextbausteinContenBearbeiten from "@/components/Vorlagen/TextbausteinContentBearbeiten.vue";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import { Textbaustein } from '@/models/VorlageModels';

export default defineComponent({
  name: "EditTextbaustein",

  components: {
    LoadingSpinner,
    TextbausteinContenBearbeiten,
  },

  props: {
    textbausteinid: {
      type: String as PropType<string>,
      default: "0"
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const getVariablenLoading = computed(() => store.getters.status.getVariablenLoading)
    const variablenErrorMsg = computed(() => store.getters.status.variablenErrorMsg)

    const variablen = computed(() => store.getters.variablen)
    function loadVariablen() {
      store.dispatch(VariablenActionTypes.GetVariablen, undefined)
    }

    const loading = computed(() => store.getters.status.createTextbausteinLoading || store.getters.status.updateTextbausteinLoading || store.getters.status.deleteTextbausteinLoading)
    const errorMsg = computed(() => store.getters.status.textbausteinErrorMsg)

    const textbaustein = computed(() => store.getters.textbausteine.find((textbaustein: Textbaustein) => textbaustein.id == Number.parseInt(props.textbausteinid)))
    const editTextbaustein = ref<Textbaustein>(textbaustein.value != null ? JSON.parse(JSON.stringify(textbaustein.value)) as Textbaustein : new Textbaustein())

    function addAbschnitt() {
      const abschnitt = new Textbaustein();
      abschnitt.position = editTextbaustein.value.abschnitte.length + 1;
      editTextbaustein.value.abschnitte.push(abschnitt);
    }

    function removeAbschnitt(index: number) {
      editTextbaustein.value.abschnitte.splice(index, 1);
      editTextbaustein.value.abschnitte.forEach((abschnitt, i) => {
        abschnitt.position = i + 1;
      });
    }

    function abschnittPosUp(index: number) {
      editTextbaustein.value.abschnitte[index - 1].position++;
      editTextbaustein.value.abschnitte[index].position--;
    }

    function abschnittPosDown(index: number) {
      editTextbaustein.value.abschnitte[index + 1].position--;
      editTextbaustein.value.abschnitte[index].position++;
    }


    function cancel() {
      editTextbaustein.value = new Textbaustein();
      router.back();
    }

    function sanitizeHTML(htmlString: string): string {
      // Regular expression to remove <script> tags and JavaScript code
      const sanitizedHTML = htmlString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
      return sanitizedHTML;
    }

    function createOrUpdateTextbaustein() {
      editTextbaustein.value.text = sanitizeHTML(editTextbaustein.value.text);

      editTextbaustein.value.abschnitte.forEach((abschnitt: Textbaustein) => {
        abschnitt.text = sanitizeHTML(abschnitt.text);
      });

      if (textbaustein.value != null) {
        store.dispatch(VorlageActionTypes.UpdateTextbaustein, editTextbaustein.value)
        .then(() => {
          if (errorMsg.value == "") {
            cancel();
          }
        })
      }
      else {
        store.dispatch(VorlageActionTypes.CreateTextbaustein, editTextbaustein.value)
        .then(() => {
          if (errorMsg.value == "") {
            cancel();
          }
        })
      }
    }

    function deleteTextbaustein() {
      store.dispatch(VorlageActionTypes.DeleteTextbaustein, Number.parseInt(props.textbausteinid))
      .then(() => {
        if (errorMsg.value == "") {
          cancel();
        }
      })
    }

    return {
      getVariablenLoading,
      variablenErrorMsg,
      variablen,
      loadVariablen,

      loading,
      errorMsg,
      textbaustein,
      editTextbaustein,
      addAbschnitt,
      removeAbschnitt,
      abschnittPosUp,
      abschnittPosDown,

      cancel,
      createOrUpdateTextbaustein,
      deleteTextbaustein
    }
  },

  mounted () {
    if (this.variablen.length == 0) {
      this.loadVariablen();
    }
  }
})
</script>