<template>
  <div class="bg-white editor-container">
    <div ref="editorRef"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { Delta } from 'quill/core';

export default defineComponent({

  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    let editor: Quill | null = null;
    const editorRef = ref<HTMLDivElement | null>(null);

    const toolbarOptions = [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      // [{ 'font': [] }], // Add font dropdown here
      // [{ 'size': []}],
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // [{ 'font': [] }],
      // ['blockquote'],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // ['image'],

      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction


      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ];

    function sanitizeHTML(htmlString: string): string {
      // Regular expression to remove <script> tags and JavaScript code
      return htmlString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
    }

    function convertHtmlToDelta(html: string): Delta | undefined {
      if (editor) {
        return editor.clipboard.convert({html: html});
      }

      return undefined;
    }

    onMounted(async () => {
      if (editorRef.value) {
        editor = new Quill(editorRef.value, {
          modules: {
            toolbar: toolbarOptions,
          },
          theme: 'snow',
        });


        // Set the initial content
        if (props.modelValue) {
          const value = convertHtmlToDelta(sanitizeHTML(props.modelValue))
          if (value) {
            editor.setContents(value, "silent");
          }
        }

        // Emit updates to modelValue when content changes
        editor.on('text-change', () => {
          const semanticHTML = editor?.getSemanticHTML();
          emit('update:modelValue', sanitizeHTML(semanticHTML || ''));
        });
      }
    })

    return {
      editor,
      editorRef,
      toolbarOptions,
    }
  },
})
</script>

<style>
.ql-container .ql-editor {
  min-height: 300px !important;
}
</style>