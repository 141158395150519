<template>
  <div class="mb-3">
    <label :for="'inputField-' + index" class="form-label">{{label}}</label>
    <input
      type="text"
      class="form-control"
      :id="'inputField' + index"
      v-model="inputvalue"
      @input="validateInput"
      :class="{ 'is-invalid': !isValid && inputvalue.length > 0, 'is-valid': isValid }"
    />
    <div v-if="!isValid && inputvalue.length > 0" class="invalid-feedback">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from 'vue';

export default defineComponent({
  name: "TextInput",

  props: {
    index: {
      type: Number as PropType<number>,
      default: 0,
    },
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Text',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 40,
    },
    inputType: {
      type: String as PropType<string>,
      default: 'text', //text, int, float, currency
    },

  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    // Reactive properties
    const inputvalue = ref(props.modelValue);

    const validationErrorMsg = ref('');
    const regexPattern = computed<string>(() => {
      const min = props.min ?? 0;
      const max = props.max ?? Infinity;

      switch (props.inputType) {
        case 'text':
          validationErrorMsg.value = `Die Eingabe muss zwischen ${min} und ${max} Zeichen lang sein.`;
          // Regex for any character (including numbers and special characters) with min/max length
          return `^.{${min},${max}}$`;
        case 'int':
          validationErrorMsg.value = `Es dürfen nur Ganzzahlen eingegeben werden.`;
          // Regex for integer with min/max length
          return `^[-+]?\\d+$`;
        case 'float':
          validationErrorMsg.value = `Es dürfen nur Ganz- oder Kommazahlen eingegeben werden (Dezimaltrennzeichen: . oder ,).`;
          // Regex for float number with min/max length before and after decimal
          return `^[-+]?\\d*[.,]?\\d+$`;
        case 'currency':
          validationErrorMsg.value = `Die Eingabe muss als Währung formatiert sein (bspw. 3000 € oder 3.000,00 €).`;
          // Regex for currency format with optional currency symbol, with min/max length before and after decimal
          return `^([$€£] ?)?\\d{1,3}([.,]?\\d{3})*([.,]\\d{2})?( ?[$€£])?$`;
        default:
          validationErrorMsg.value = `Die Eingabe muss zwischen ${min} und ${max} Zeichen lang sein.`;
          // Fallback regex if no input type matches
          return `^.{${min},${max}}$`;
      }
    });

    // Computed property for validation
    const isValid = computed(() => {
      if (inputvalue.value == null || inputvalue.value?.length === 0) return true;
      return (new RegExp(regexPattern.value)).test(inputvalue.value)
    });
    const errorMessage = ref('');

    // Method to validate input
    const validateInput = () => {
      if (!isValid.value && inputvalue.value.length > 0) {
        errorMessage.value = validationErrorMsg.value;
      } else {
        errorMessage.value = '';
      }

      emit('update:modelValue', inputvalue.value);
    };

    return {
      inputvalue,
      isValid,
      validateInput,
      errorMessage,
    };
  },
})
</script>