<template>
  <h1 v-if="vertrag != null">{{ vertrag.titel }} bearbeiten</h1>
  <h1 v-else>Neuer Vertrag</h1>

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <!-- SELECT VERTRAGSPARTEIEN -->
  <section v-if="displaySection == 0" class="mt-4">
    <h2>Vertragslaufzeit</h2>

    <div class="py-2 border shadow">
      <div class="row mx-1">
        <div class="col">
          <label for="vertrag-beginn" class="form-label">Vertragsbeginn</label>
          <input type="date" class="form-control" id="vertrag-beginn" v-model="startDatumConverter" />
        </div>

        <div class="col">
          <label for="vertrag-ende" class="form-label">Vertragsende</label>
          <input type="date" class="form-control" id="vertrag-ende" v-model="endeDatumConverter" />
        </div>
      </div>

      <hr class="my-3" />

      <div class="row mx-1">
        <div class="col-6">
          <label for="vertrag-delete" class="form-label">Löschdatum</label>
          <input type="date" class="form-control" id="vertrag-delete" v-model="deleteDatumConverter" />
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <h2>Bitte wählen Sie die Vertragsparteien aus</h2>

    <div class="row mx-1">
      <div class="col me-3 p-3 border shadow">
        <h3>Vertragspartner 1</h3>

        <div>
          <p><strong>Vertragspartner auswählen:</strong></p>

          <div class="row">
            <div class="col-6">
              <label for="partner-1-select" class="form-label">Vertragspartner</label>
              <select
                id="partner-1-select"
                class="form-select"
                aria-label="Vartragspartner auswählen"
                v-model="selectedPartner1"
                @change="setVertragVertragspartner1()"
              >
                <option selected :value="null">Vertragspartner auswählen</option>
                <option v-for="partner in vertragspartner" :key="partner.id" :value="partner">
                  <span v-if="partner.unternehmen != ''">{{ partner.unternehmen }} |</span
                  ><span v-if="partner.vorname != '' || partner.name != ''"> {{ partner.vorname }} {{ partner.name }}</span>
                </option>
              </select>
            </div>
          </div>
        </div>

        <hr class="my-4" />

        <div class="mb-3">
          <label for="partei-1-unternehmen" class="form-label">Unternhemen</label>
          <input type="text" class="form-control" id="partei-1-unternehmen" v-model="editVertrag.vertragspartei1.unternehmen" />
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="partei-1-vorname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="partei-1-vorname" v-model="editVertrag.vertragspartei1.vorname" />
          </div>

          <div class="col">
            <label for="partei-1-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="partei-1-name" v-model="editVertrag.vertragspartei1.name" />
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-1-strasse" class="form-label">Straße & Hausnummer</label>
          <input type="text" class="form-control" id="partei-1-strasse" v-model="editVertrag.vertragspartei1.strasse" />
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <label for="partei-1-plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="partei-1-plz" v-model="editVertrag.vertragspartei1.plz" />
          </div>

          <div class="col">
            <label for="partei-1-ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="partei-1-ort" v-model="editVertrag.vertragspartei1.ort" />
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-1-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="partei-1-land" v-model="editVertrag.vertragspartei1.land" />
        </div>
      </div>

      <div class="col ms-3 p-3 border shadow">
        <h3>Vertragspartner 2</h3>

        <div>
          <p><strong>Vertragspartner oder Mitarbeiter auswählen:</strong></p>

          <div class="row">
            <div class="col">
              <label for="partner-2-select" class="form-label">Vertragspartner</label>
              <select
                id="partner-2-select"
                class="form-select"
                aria-label="Vartragspartner auswählen"
                v-model="selectedPartner2"
                @change="setVertragVertragspartner2()"
              >
                <option selected :value="null">Vertragspartner auswählen</option>
                <option v-for="partner in vertragspartner" :key="partner.id" :value="partner">
                  <span v-if="partner.unternehmen != ''">{{ partner.unternehmen }} |</span
                  ><span v-if="partner.vorname != '' || partner.name != ''"> {{ partner.vorname }} {{ partner.name }}</span>
                </option>
              </select>
            </div>

            <div class="col">
              <label for="mitarbeiter-2-select" class="form-label">Mitarbeiter</label>
              <select
                id="mitarbeiter-2-select"
                class="form-select"
                aria-label="Mitarbeiter auswählen"
                v-model="selectedMitarbeiter2"
                @change="setVertragMitarbeiter2()"
              >
                <option selected :value="null">Mitarbeiter auswählen</option>
                <option v-for="pers in mitarbeiter" :key="pers.id" :value="pers">{{ pers.guid }} | {{ pers.vorname }} {{ pers.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <hr class="my-4" />

        <div class="mb-3">
          <label for="partei-2-unternehmen" class="form-label">Unternhemen</label>
          <input type="text" class="form-control" id="partei-2-unternehmen" v-model="editVertrag.vertragspartei2.unternehmen" />
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="partei-2-vorname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="partei-2-vorname" v-model="editVertrag.vertragspartei2.vorname" />
          </div>

          <div class="col">
            <label for="partei-2-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="partei-2-name" v-model="editVertrag.vertragspartei2.name" />
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-2-strasse" class="form-label">Straße & Hausnummer</label>
          <input type="text" class="form-control" id="partei-2-strasse" v-model="editVertrag.vertragspartei2.strasse" />
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <label for="partei-2-plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="partei-2-plz" v-model="editVertrag.vertragspartei2.plz" />
          </div>

          <div class="col">
            <label for="partei-2-ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="partei-2-ort" v-model="editVertrag.vertragspartei2.ort" />
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-2-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="partei-2-land" v-model="editVertrag.vertragspartei2.land" />
        </div>

        <p class="small text-muted p-0 m-0"><strong>Personal Guid:</strong> {{ editVertrag.personaldatenGuid }}</p>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-5">
      <button class="btn btn-secondary" @click="nextSection()">Weiter</button>
    </div>
  </section>

  <!-- SELECT VORLAGE -->
  <section v-if="displaySection == 1" class="mt-4">
    <h2>Bitte wählen Sie eine Vorlage aus</h2>

    <div v-if="selectedVorlage != null" class="alert alert-info">
      <font-awesome-icon icon="info-circle" /> <strong>Wichtig!</strong> Wenn Sie die Vorlage ändern, werden alle bisherigen Abschnitte und Eingaben gelöscht.
    </div>

    <div class="row mx-1 mb-2">
      <div class="col-8 fw-bold my-auto">Titel</div>
      <div class="col-4 fw-bold my-auto">Abschnitte</div>
    </div>

    <div
      v-for="vorlage in vorlagen"
      :key="vorlage.id"
      class="row mx-1 mb-3 py-3 shadow-sm vorlageRow"
      :class="{ selectedVorlage: vorlage.id == selectedVorlage?.id }"
      @click="selectVorlage(vorlage.id)"
    >
      <div class="col-8">
        <strong>{{ vorlage.name }}</strong> ({{ vorlage.titel }})
      </div>

      <div class="col-4">{{ vorlage.vorlageTextbausteine.length }}</div>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button class="btn btn-secondary me-3" @click="prevSection()">Zurück</button>

      <button class="btn btn-secondary" @click="nextSection()" :disabled="selectedVorlage == null">Weiter</button>
    </div>
  </section>

  <!-- SELECT ABSCHNITTE -->
  <section v-else-if="displaySection == 2 && selectedVorlage != null" class="mt-4">
    <h2>Bitte wählen Sie die Vertragsabschnitte aus</h2>

    <div v-for="(abschnitt, idx) in editVertrag.textabschnitte.sort((a, b) => a.position - b.position)" class="d-flex mb-2">
      <div class="d-flex flex-column my-auto me-3">
        <button class="btn p-0" v-if="abschnitt.position != 1" @click="abschnittMoveUp(idx)"><font-awesome-icon icon="chevron-up" /></button>
        <button class="btn p-0" v-if="abschnitt.position != editVertrag.textabschnitte.length" @click="abschnittMoveDown(idx)">
          <font-awesome-icon icon="chevron-down" />
        </button>
      </div>

      <div class="flex-fill">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input"
            type="checkbox"
            :id="'abschnitt-' + idx + '-' + abschnitt.id"
            v-model="abschnitt.selected"
            @change="deselectChildren(idx)"
          />
          <label
            class="form-check-label flex-fill ms-2 p-2 shadow-sm abschnittRow"
            :class="{ selectedAbschnitt: abschnitt.selected }"
            :for="'abschnitt-' + idx + '-' + abschnitt.id"
          >
            <!-- <strong>{{ abschnitt.textbaustein.titel }}</strong> ({{ abschnitt.textbaustein.name }}) -->
            <SelectAbschnittRow :abschnitt="abschnitt" />
          </label>
        </div>

        <div v-for="(subabschnitt, idx2) in abschnitt.abschnitte" :key="idx2" class="ms-5 d-flex align-items-center">
          <input
            class="form-check-input"
            type="checkbox"
            :id="'subabschnitt-' + idx2 + '-' + subabschnitt.id"
            v-model="subabschnitt.selected"
            @change="setParentAsSelected(idx, idx2)"
          />
          <label
            class="form-check-label flex-fill ms-2 p-2 shadow-sm abschnittRow"
            :class="{ selectedAbschnitt: subabschnitt.selected }"
            :for="'subabschnitt-' + idx2 + '-' + subabschnitt.id"
          >
            <!-- <strong>{{ subabschnitt.textbaustein.titel }}</strong> ({{ subabschnitt.textbaustein.name }}) -->
            <SelectAbschnittRow :abschnitt="subabschnitt" />
          </label>
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <div class="d-flex justify-content-center mt-3">
      <button class="btn btn-secondary me-3" @click="prevSection()">Zurück</button>
      <button class="btn btn-secondary" @click="generateInputs()" :disabled="editVertrag.textabschnitte.length == 0">Weiter</button>
    </div>

    <!-- <pre>{{ editVertrag.textabschnitte }}</pre> -->
  </section>

  <!-- VARIABLEN INPUT -->
  <section v-else-if="displaySection == 3" class="mt-4">
    <div
      v-for="(input, idx) in editVertrag.inputs
        .filter((a) => a.variable.herkunft.toLowerCase() != 'vertrag')
        .sort((a, b) => a.variable.titel.localeCompare(b.variable.titel))"
      class="w-50 mx-auto"
    >
      <AbschnittInput :input="input" :index="idx" />
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button class="btn btn-secondary me-3" @click="prevSection()">Zurück</button>
      <!-- <button class="btn btn-primary" @click="saveVertrag()">Speichern</button> -->
    </div>
  </section>

  <hr class="my-4" />

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <div class="d-flex justify-content-end">
    <button class="btn btn-secondary" @click="cancel()">Abbrechen</button>
    <button v-if="editVertrag.id != 0 || displaySection == 3" class="btn btn-primary ms-3" @click="saveVertrag()">Speichern</button>
  </div>

  <!-- <pre>{{ editVertrag }}</pre> -->
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, ref, PropType, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { useDateConverter } from '@/utils/date_converter';

  import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';
  import { ActionTypes as VorlagenActionTypes } from '@/store/modules/Vorlagen/actions';
  import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';
  import { ActionTypes as VertragspartnerActionTypes } from '@/store/modules/Vertragspartner/actions';

  import { Personaldaten } from '@/models/PersonaldatenModels';
  import { Input, TextAbschnitt, Vertrag } from '@/models/VertragModels';
  import { Textbaustein, Vorlage, VorlageTextbaustein, Variable } from '@/models/VorlageModels';
  import { Vertragspartner } from '@/models/VertragspartnerModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AbschnittInput from '@/components/Vertrag/AbschnittInput.vue';
  import SelectAbschnittRow from '@/components/Vertrag/SelectAbschnittRow.vue';
  //#endregion

  const props = defineProps({
    vertragid: {
      type: String as PropType<string>,
      default: '',
    },

    guid: {
      type: String as PropType<string>,
      default: '',
    },
  });

  const store = useStore();
  const router = useRouter();

  const { dateToDateInputConverter } = useDateConverter();

  const personaldaten = computed<Personaldaten>(() => {
    if (selectedMitarbeiter2.value != null) {
      return selectedMitarbeiter2.value;
    } else {
      return new Personaldaten();
    }
  });

  //#region Sections
  const displaySection = ref(0);

  function nextSection() {
    if (editVertrag.value.id != 0 && displaySection.value == 0) {
      displaySection.value = 2;
    } else {
      displaySection.value++;
    }
  }

  function prevSection() {
    if (editVertrag.value.id != 0 && displaySection.value == 2) {
      displaySection.value = 0;
    } else if (displaySection.value > 0) {
      displaySection.value--;
    }
  }
  //#endregion

  //#region Vorlage
  const vorlagen = computed(() => store.getters.vorlagen);
  const selectedVorlage = ref<Vorlage>();
  //#endregion

  //#region Vertrag
  const vertrag = computed(() => store.getters.vertraege.find((v: Vertrag) => v.id == parseInt(props.vertragid)));

  const editVertrag = ref<Vertrag>(vertrag.value != null ? (JSON.parse(JSON.stringify(vertrag.value)) as Vertrag) : (new Vertrag() as Vertrag));
  //#endregion

  //#region Vertragslaufzeit
  const startDatumConverter = computed({
    get() {
      if (editVertrag.value.vertragsBeginn != null) {
        const date = new Date(editVertrag.value.vertragsBeginn);
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      } else {
        return null;
      }
    },

    set(value) {
      if (value != null && value != '') {
        editVertrag.value.vertragsBeginn = new Date(value);
      } else {
        editVertrag.value.vertragsBeginn = null;
      }
    },
  });

  const endeDatumConverter = computed({
    get() {
      if (editVertrag.value.vertragsEnde != null) {
        const date = new Date(editVertrag.value.vertragsEnde);
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      } else {
        return null;
      }
    },

    set(value) {
      if (value != null && value != '') {
        editVertrag.value.vertragsEnde = new Date(value);
      } else {
        editVertrag.value.vertragsEnde = null;
      }
    },
  });

  const deleteDatumConverter = computed({
    get() {
      if (editVertrag.value.ddate != null) {
        const date = new Date(editVertrag.value.ddate);
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      } else {
        return null;
      }
    },

    set(value) {
      if (value != null && value != '') {
        editVertrag.value.ddate = new Date(value);
      } else {
        editVertrag.value.ddate = null;
      }
    },
  });
  //#endregion

  //#region Vertragspartner
  const vertragspartner = computed(() => store.getters.vertragspartner);
  const mitarbeiter = computed(() => store.getters.personaldaten);

  const selectedPartner1 = ref<Vertragspartner | null>(null);
  function setVertragVertragspartner1() {
    if (selectedPartner1.value != null) {
      editVertrag.value.vertragspartei1.name = selectedPartner1.value.name;
      editVertrag.value.vertragspartei1.vorname = selectedPartner1.value.vorname;
      editVertrag.value.vertragspartei1.unternehmen = selectedPartner1.value.unternehmen;
      editVertrag.value.vertragspartei1.strasse = selectedPartner1.value.strasse;
      editVertrag.value.vertragspartei1.plz = selectedPartner1.value.plz;
      editVertrag.value.vertragspartei1.ort = selectedPartner1.value.ort;
      editVertrag.value.vertragspartei1.land = selectedPartner1.value.land;
    } else {
      editVertrag.value.vertragspartei1.name = '';
      editVertrag.value.vertragspartei1.vorname = '';
      editVertrag.value.vertragspartei1.unternehmen = '';
      editVertrag.value.vertragspartei1.strasse = '';
      editVertrag.value.vertragspartei1.plz = '';
      editVertrag.value.vertragspartei1.ort = '';
      editVertrag.value.vertragspartei1.land = '';
    }
  }

  const selectedPartner2 = ref<Vertragspartner | null>(null);
  function setVertragVertragspartner2() {
    selectedMitarbeiter2.value = null;
    if (selectedPartner2.value != null) {
      editVertrag.value.vertragspartei2.name = selectedPartner2.value.name;
      editVertrag.value.vertragspartei2.vorname = selectedPartner2.value.vorname;
      editVertrag.value.vertragspartei2.unternehmen = selectedPartner2.value.unternehmen;
      editVertrag.value.vertragspartei2.strasse = selectedPartner2.value.strasse;
      editVertrag.value.vertragspartei2.plz = selectedPartner2.value.plz;
      editVertrag.value.vertragspartei2.ort = selectedPartner2.value.ort;
      editVertrag.value.vertragspartei2.land = selectedPartner2.value.land;

      editVertrag.value.personaldatenGuid = '';
    } else {
      editVertrag.value.vertragspartei2.name = '';
      editVertrag.value.vertragspartei2.vorname = '';
      editVertrag.value.vertragspartei2.unternehmen = '';
      editVertrag.value.vertragspartei2.strasse = '';
      editVertrag.value.vertragspartei2.plz = '';
      editVertrag.value.vertragspartei2.ort = '';
      editVertrag.value.vertragspartei2.land = '';

      editVertrag.value.personaldatenGuid = '';
    }
  }

  const selectedMitarbeiter2 = ref<Personaldaten | null>(null);

  if (props.guid != '') {
    const persdata = store.getters.personaldaten.find((p: Personaldaten) => p.guid == props.guid);
    if (persdata != undefined) {
      selectedMitarbeiter2.value = persdata;
      setVertragMitarbeiter2();
    }
  }

  function setVertragMitarbeiter2() {
    selectedPartner2.value = null;
    if (selectedMitarbeiter2.value != null) {
      editVertrag.value.vertragspartei2.unternehmen = '';
      editVertrag.value.vertragspartei2.name = selectedMitarbeiter2.value.name;
      editVertrag.value.vertragspartei2.vorname = selectedMitarbeiter2.value.vorname;
      editVertrag.value.vertragspartei2.strasse = selectedMitarbeiter2.value.strasse;
      editVertrag.value.vertragspartei2.plz = selectedMitarbeiter2.value.plz;
      editVertrag.value.vertragspartei2.ort = selectedMitarbeiter2.value.ort;
      editVertrag.value.vertragspartei2.land = selectedMitarbeiter2.value.land;

      editVertrag.value.personaldatenGuid = selectedMitarbeiter2.value.guid;
    } else {
      editVertrag.value.vertragspartei2.name = '';
      editVertrag.value.vertragspartei2.vorname = '';
      editVertrag.value.vertragspartei2.unternehmen = '';
      editVertrag.value.vertragspartei2.strasse = '';
      editVertrag.value.vertragspartei2.plz = '';
      editVertrag.value.vertragspartei2.ort = '';
      editVertrag.value.vertragspartei2.land = '';

      editVertrag.value.personaldatenGuid = '';
    }
  }
  //#endregion

  //#region select Vorlage und Abschnitte
  function selectVorlage(id: number) {
    selectedVorlage.value = JSON.parse(JSON.stringify(vorlagen.value.find((v: Vorlage) => v.id == id))) as Vorlage;

    editVertrag.value.titel = selectedVorlage.value.titel;
    editVertrag.value.vorlageid = selectedVorlage.value.id;
    editVertrag.value.textabschnitte = new Array<TextAbschnitt>();
    editVertrag.value.inputs = new Array<Input>();

    selectedVorlage.value?.vorlageTextbausteine.forEach((abschnitt: VorlageTextbaustein) => {
      const vertragSubAbschnitt = new Array<TextAbschnitt>();

      abschnitt.textbaustein.abschnitte.forEach((subabschnitt: Textbaustein) => {
        const newSubabschnitt = new TextAbschnitt(
          0,
          editVertrag.value.id,
          subabschnitt.position,
          !abschnitt.optional,
          subabschnitt.id,
          null,
          new Array<TextAbschnitt>()
        );
        newSubabschnitt.textbaustein = subabschnitt;
        vertragSubAbschnitt.push(newSubabschnitt);
      });

      const vertragAbschnitt = new TextAbschnitt(
        0,
        editVertrag.value.id,
        abschnitt.position,
        !abschnitt.optional,
        abschnitt.textbaustein.id,
        null,
        vertragSubAbschnitt
      );
      vertragAbschnitt.textbaustein = abschnitt.textbaustein;

      if (editVertrag.value.textabschnitte.findIndex((a: TextAbschnitt) => a.textbausteinid == abschnitt.textbaustein.id) == -1) {
        editVertrag.value.textabschnitte.push(vertragAbschnitt);
      }
    });

    nextSection();
  }

  function setParentAsSelected(abschnittIdx: number, subabschnittIdx: number) {
    var abschnitt = editVertrag.value.textabschnitte.sort((a, b) => a.position - b.position)[abschnittIdx];
    if (abschnitt) {
      var subabschnitt = abschnitt.abschnitte.sort((sa, sb) => sa.position - sb.position)[subabschnittIdx];
      if (subabschnitt && subabschnitt.selected) {
        abschnitt.selected = true;
      }
    }
  }

  function deselectChildren(abschnittIdx: number) {
    var abschnitt = editVertrag.value.textabschnitte.sort((a, b) => a.position - b.position)[abschnittIdx];
    if (abschnitt && abschnitt.selected == false) {
      abschnitt.abschnitte.forEach((sa: TextAbschnitt) => {
        sa.selected = false;
      });
    }
  }

  function abschnittMoveUp(index: number) {
    if (index > 0) {
      var abschnitte = editVertrag.value.textabschnitte.filter((a: TextAbschnitt) => a.ownerid == null).sort((a, b) => a.position - b.position);
      abschnitte[index - 1].position += 1;
      abschnitte[index].position -= 1;
    }
  }

  function abschnittMoveDown(index: number) {
    var abschnitte = editVertrag.value.textabschnitte.filter((a: TextAbschnitt) => a.ownerid == null).sort((a, b) => a.position - b.position);
    if (index < abschnitte.length - 1) {
      abschnitte[index + 1].position -= 1;
      abschnitte[index].position += 1;
    }
  }
  //#endregion

  //#region Inputs
  const variablen = computed(() => store.getters.variablen);

  function generateInputs() {
    const variableRegex = /&lt;&lt;([^&]+)&gt;&gt;/g;
    const newInputs = new Array<Input>();

    // Generate new inputs from selected textabschnitte
    editVertrag.value.textabschnitte
      .filter((t) => t.selected)
      .forEach((t) => {
        let match;
        while ((match = variableRegex.exec(t.textbaustein.text)) !== null) {
          const variableText = `<<${match[1]}>>`;
          const variable = variablen.value.find((v: Variable) => v.platzhalter == variableText);

          if (variable != null && newInputs.findIndex((i) => i.variableid == variable.id) == -1) {
            const newInput = new Input(variable.id, variable, editVertrag.value.id, '');

            if (variable.herkunft == 'Personaldaten' && variable.feld != null && variable.feld != '' && personaldaten.value.hasOwnProperty(variable.feld)) {
              if (variable.type == 'date') {
                newInput.value = dateToDateInputConverter(personaldaten.value[variable.feld as keyof Personaldaten].toString());
              } else {
                newInput.value = personaldaten.value[variable.feld as keyof Personaldaten].toString();
              }
            }

            if (variable.herkunft == 'Vertrag' && variable.feld != null && variable.feld != '') {
              if (variable.feld.includes('.')) {
                const felder = variable.feld.split('.');
                if (variable.type == 'date') {
                  const parentField = editVertrag.value[felder[0] as keyof Vertrag];
                  if (parentField && typeof parentField === 'object' && felder[1] in parentField) {
                    newInput.value = dateToDateInputConverter((parentField as any)[felder[1]].toString());
                  }
                } else {
                  const parentField = editVertrag.value[felder[0] as keyof Vertrag];
                  if (parentField && typeof parentField === 'object' && felder[1] in parentField) {
                    newInput.value = (parentField as any)[felder[1]];
                  }
                }
              } else if (editVertrag.value.hasOwnProperty(variable.feld)) {
                if (variable.type == 'date') {
                  newInput.value = dateToDateInputConverter(editVertrag.value[variable.feld as keyof Vertrag]?.toString() || '');
                } else {
                  newInput.value = editVertrag.value[variable.feld as keyof Vertrag]?.toString() || '';
                }
              }
            }

            newInputs.push(newInput);
          }
        }
      });

    // Remove all inputs that are not in the newInputs
    const inputsToRemove = editVertrag.value.inputs.filter((i) => newInputs.findIndex((ni) => ni.variableid == i.variableid) == -1);
    if (inputsToRemove.length > 0) {
      editVertrag.value.inputs = editVertrag.value.inputs.filter((i) => inputsToRemove.findIndex((ei) => ei.variableid == i.variableid) == -1);
    }

    // Add all new inputs to the editVertrag
    newInputs.forEach((i) => {
      if (editVertrag.value.inputs.findIndex((ei) => ei.variableid == i.variableid) == -1) {
        editVertrag.value.inputs.push(i);
      }
    });

    nextSection();
  }
  //#endregion

  const loading = computed(() => store.getters.status.createVertragLoading || store.getters.status.updateVertragLoading);
  const vertragErrorMsg = computed(() => store.getters.status.vertragErrorMsg);

  //#region Speichern
  function updateVertragInputs() {
    editVertrag.value.inputs
      .filter((i) => i.variable.herkunft.toLowerCase() == 'vertrag')
      .forEach((i) => {
        if (i.variable.feld.includes('.')) {
          const felder = i.variable.feld.split('.');
          if (i.variable.type == 'date') {
            const parentField = editVertrag.value[felder[0] as keyof Vertrag];
            if (parentField && typeof parentField === 'object' && felder[1] in parentField) {
              i.value = dateToDateInputConverter((parentField as any)[felder[1]].toString());
            }
          } else {
            const parentField = editVertrag.value[felder[0] as keyof Vertrag];
            if (parentField && typeof parentField === 'object' && felder[1] in parentField) {
              i.value = (parentField as any)[felder[1]];
            }
          }
        } else if (editVertrag.value.hasOwnProperty(i.variable.feld)) {
          if (i.variable.type == 'date') {
            i.value = dateToDateInputConverter(editVertrag.value[i.variable.feld as keyof Vertrag]?.toString() || '');
          } else {
            i.value = editVertrag.value[i.variable.feld as keyof Vertrag]?.toString() || '';
          }
        }
      });
  }

  function saveVertrag() {
    updateVertragInputs();

    if (vertrag.value != null) {
      store.dispatch(VertragActionTypes.UpdateVertrag, editVertrag.value).then(() => {
        if (vertragErrorMsg.value == '') {
          router.push({ name: 'DetailsVertrag', query: { vertragid: props.vertragid, guid: props.guid } });
        }
      });
    } else {
      store.dispatch(VertragActionTypes.CreateVertrag, editVertrag.value).then(() => {
        if (vertragErrorMsg.value == '') {
          if (props.vertragid == '') {
            router.push({ name: 'Vertraege' });
          } else {
            router.push({ name: 'DetailsVertrag', query: { vertragid: props.vertragid, guid: props.guid } });
          }
        }
      });
    }
  }
  //#endregion

  function cancel() {
    router.back();
  }

  function setEditVertragData() {
    if (editVertrag.value.vertragspartei1 == null) {
      editVertrag.value.vertragspartei1 = new Vertragspartner();
    }
    if (editVertrag.value.vertragspartei2 == null) {
      editVertrag.value.vertragspartei2 = new Vertragspartner();
    }

    selectedVorlage.value = vorlagen.value.find((v: Vorlage) => v.id == editVertrag.value.vorlageid);

    // Set selected Vertragspartner 1
    const partner1 = vertragspartner.value.find(
      (v: Vertragspartner) =>
        v.name == editVertrag.value.vertragspartei1.name &&
        v.vorname == editVertrag.value.vertragspartei1.vorname &&
        v.unternehmen == editVertrag.value.vertragspartei1.unternehmen
    );
    if (partner1 != undefined) {
      selectedPartner1.value = partner1;
    }

    // Set selected Vertragspartner 2
    if (editVertrag.value.personaldatenGuid) {
      const pers = store.getters.personaldaten.find((p: Personaldaten) => p.guid == editVertrag.value.personaldatenGuid);
      if (pers != undefined) {
        selectedMitarbeiter2.value = pers;
      }
    } else {
      const partner2 = vertragspartner.value.find(
        (v: Vertragspartner) =>
          v.name == editVertrag.value.vertragspartei2.name &&
          v.vorname == editVertrag.value.vertragspartei2.vorname &&
          v.unternehmen == editVertrag.value.vertragspartei2.unternehmen
      );
      if (partner2 != undefined) {
        selectedPartner2.value = partner2;
      }
    }
  }

  if (vertrag.value != null && vertrag.value.id != 0) {
    setEditVertragData();
  }

  onMounted(() => {
    store.dispatch(VorlagenActionTypes.GetVorlagen, undefined);
    store.dispatch(VorlagenActionTypes.GetTextbausteine, undefined);
    store.dispatch(VariablenActionTypes.GetVariablen, undefined);
    store.dispatch(VertragspartnerActionTypes.GetVertragspartner, undefined);
  });
</script>

<style scoped>
  .vorlageRow {
    background: white;
    cursor: pointer;
  }

  .vorlageRow:hover {
    /* background: #ffd1df;
  color: #770025; */
    background: #fff4af;
  }

  .selectedVorlage {
    /* background: #ffd1df;
  color: #770025; */
    background-color: #fff4af;
  }

  .abschnittRow {
    background: white;
    cursor: pointer;
  }

  .abschnittRow:hover {
    /* background: #ffd1df;
  color: #770025; */
    background: #fff4af;
  }

  .selectedAbschnitt {
    /* background: #ffd1df;
  color: #770025; */
    background: #fff4af;
  }
</style>
