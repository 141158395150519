import { createApp } from 'vue'

import App from './App.vue'
// import Emitter from 'tiny-emitter';
import router from './router'
import {store} from './store'
// import './registerServiceWorker'


import { library } from "@fortawesome/fontawesome-svg-core";

import { faWindows } from '@fortawesome/free-brands-svg-icons'

import {
  faArrowDownLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowUpLong,
  faBars,
  faBoxOpen,
  faBoxesStacked,
  faCertificate,

  faCheck,

  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,

  faCircleCheck,

  faCircleInfo,

  faClone,

  faEnvelope,
  faFileContract,
  faFileDownload,
  faFileLines,
  faFilePdf,
  faFloppyDisk,
  faGear,
  faListCheck,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMinus,
  faPaperPlane,
  faPen,
  faPenRuler,
  faPenToSquare,
  faPlus,
  faPrint,
  faRotate,
  faTableColumns,
  faTrash,
  faUsers,
  faUsersViewfinder,
  faWarehouse,
  faXmark
} from "@fortawesome/free-solid-svg-icons";


library.add(
  faArrowDownLong,
  faArrowUpLong,

  faArrowRightFromBracket,
  faArrowRightToBracket,

  faBars,
  faBoxOpen,
  faBoxesStacked,

  faCertificate,
  faCheck,
  faCircleCheck,
  faCircleInfo,

  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,

  faClone,
  
  faEnvelope,
  faFileContract,
  faFileDownload,
  faFileLines,
  faFilePdf,
  faFloppyDisk,

  faGear,

  faListCheck,

  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  
  faMinus,
  faPaperPlane,
  faPen,
  faPenRuler,
  faPenToSquare,
  faPlus,
  faPrint,
  faRotate,
  faTableColumns,
  faTrash,
  faUsers,
  faUsersViewfinder,
  faWarehouse,
  faXmark,
  faWindows
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
// app.config.globalProperties.$msalInstance = {};
// app.config.globalProperties.$emitter = new Emitter();

app.use(store)
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

import "bootstrap"