<template>
  <div class="mb-3">
    <div v-if="input.variable != null">
      <TextInput
        v-if="input.variable.type == 'text'"
        :index="index"
        v-model="input.value"
        :label="input.variable.titel + ' (' + input.variable.platzhalter + ')'"
        :min="input.variable.minLength"
        :max="input.variable.maxLength"
        :inputType="input.variable.validationType" />
      
      <div v-else>
        <label :for="'input-' + index" class="form-label">{{ input.variable.titel }} ({{ input.variable.platzhalter }})</label>
        <input :type="input.variable.type" class="form-control" :id="'input-' + index" v-model="input.value">
      </div>
    </div>

    <div v-else class="alert alert-warning">
      Ungültige Variable (Variable ID {{ input.variableid }})
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import TextInput from "../FormInputs/TextInput.vue";

import { Input } from "@/models/VertragModels"

export default defineComponent({
  name: "AbschnittInput",

  components: {
    TextInput
  },

  props: {
    input: {
      type: Object as PropType<Input>,
      required: true
    },
    
    index: {
      type: Number,
      required: true
    }
  },

})
</script>