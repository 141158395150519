<template>
  <div class="d-flex justify-content-between align-items-center">
    <span><strong>{{ abschnitt.textbaustein.titel }}</strong> ({{ abschnitt.textbaustein.name }})</span>

    <button type="button" class="btn btn-primary" @click="showModal()"><font-awesome-icon icon="magnifying-glass-plus" /></button>
  </div>

  <div
    class="modal fade"
    :id="'abschnitt-details-' + abschnitt.textbaustein.id"
    tabindex="-1"
    aria-labelledby="abschnittDetailsModal"
    aria-hidden="true"
    ref="abschnittDetailsModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <span><strong>{{ abschnitt.textbaustein.titel }}</strong> ({{ abschnitt.textbaustein.name }})</span>

          <button
            type="button"
            class="btn-close btn-sm text-white"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body">
          <div v-html="abschnitt.textbaustein.text"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { Modal } from 'bootstrap';

import { TextAbschnitt } from '@/models/VertragModels';

export default defineComponent({
  name: "TextbausteinRow",

  props: {
    abschnitt: {
      type: Object as PropType<TextAbschnitt>,
      required: true
    }
  },

  setup() {
    
    
    const abschnittDetailsModalRef = ref()
    function closeModal() {
      Modal.getInstance(abschnittDetailsModalRef.value)?.hide();
    }
    function showModal() {
      if (abschnittDetailsModalRef.value) {
        new Modal(abschnittDetailsModalRef.value).show();
      }
    }
    
    return {
      abschnittDetailsModalRef,
      showModal,
      closeModal,
    }
  }

})
</script>