<template>
  <div class="mx-1 mb-2 p-2 bg-white shadow">

    <div class="row">
      <div class="col-10 fw-bold my-auto">{{ abschnitt.textbaustein.titel }}</div>

      <div class="col-2 my-auto d-flex justify-content-end">
        <button type="button" class="btn btn-primary" @click="toggleCollapse()"><font-awesome-icon icon="magnifying-glass-plus" /></button>
      </div>
    </div>
      
    <div class="mt-3 pt-2 border-top border-secondary" :class="{ 'collapse': isCollapsed }">
      <div class="content-text">
        <div v-html="replaceVariablen(abschnitt.textbaustein.text)"></div>
      </div>
        
      <div v-for="subabschnitt in abschnitt.abschnitte.filter(sa => sa.selected)" :key="subabschnitt.id" class="ms-4 mb-3">
        <p class="fw-bold">{{ subabschnitt.textbaustein.titel }}</p>
        <div class="content text">
          <div v-html="subabschnitt.textbaustein.text"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { Input, TextAbschnitt } from '@/models/VertragModels';

export default defineComponent({
  name: "TextbausteinRow",

  props: {
    abschnitt: {
      type: Object as PropType<TextAbschnitt>,
      required: true
    },
    inputs: {
      type: Object as PropType<Array<Input>>,
      default: new Array<Input>()
    }
  },

  setup(props) {

    function replaceVariablen (text: string) {

      let replacedText = text

      const variableRegex =  /&lt;&lt;([^&]+)&gt;&gt;/g;

      let match;
      while ((match = variableRegex.exec(text)) !== null) {
        const variablePlatzhalter = `<<${match[1].toString()}>>`
        const input = props.inputs.find(i => i.variable.platzhalter === variablePlatzhalter);

        if (input) {
          console.log(match[0], input?.value);
          replacedText = replacedText.replace(match[0], input.value);
        }
      }

      return replacedText;
    }

    const isCollapsed = ref(true)
    function toggleCollapse () {
      isCollapsed.value = !isCollapsed.value
    }
    
    return {
      replaceVariablen,

      isCollapsed,
      toggleCollapse,
    }
  }

})
</script>