<template>
  <router-link :to="{name: 'Vorlagen'}" class="btn btn-outline-secondary"><font-awesome-icon icon="chevron-left"/> Zurück</router-link>

  <div v-if="vorlage == null" class="alert alert-danger mt-5">
    Ungültige Vorlage ID: {{ vorlageid }}
  </div>

  <div v-else>
    <!-- TITEL -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1>{{ vorlage.name }}</h1>

      <router-link :to="{name: 'EditVorlage', query: {vorlageid: vorlageid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <!-- BESCHREIBUNG -->
    <div class="w-50 mb-4">
      <div class="row mb-2">
        <div class="col-3 fw-bold">Vertrag Titel:</div>
        <div class="col">{{ vorlage.titel }}</div>
      </div>
    </div>


    <!-- TEXTBAUSTEINE -->
    <p><strong>Abschnitte</strong></p>
    <div v-if="vorlage?.vorlageTextbausteine.length > 0" class="mt-3">
      <TextbausteinRow v-for="vorlageTextbaustein in vorlage.vorlageTextbausteine.sort((a: VorlageTextbaustein, b: VorlageTextbaustein) => a.position - b.position)" :key="vorlageTextbaustein.id" :textbaustein="vorlageTextbaustein.textbaustein" :showEdit="false" class="mx-1 mb-2 p-2 bg-white shadow" />
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import TextbausteinRow from '@/components/Vorlagen/TextbausteinRow.vue';

import { Vorlage, VorlageTextbaustein } from '@/models/VorlageModels';

export default defineComponent({
  name: "Kommission",

  components: {
    LoadingSpinner,
    TextbausteinRow
  },

  props: {
    vorlageid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const vorlage = computed(() => store.getters.vorlagen.find((v: Vorlage) => v.id == parseInt(props.vorlageid)))

    return {
      store,
      router,

      vorlage,
    }
  },

  mounted () {
    if (this.store.getters.textbausteine.length == 0) {
      this.store.dispatch(VorlageActionTypes.GetTextbausteine, undefined)
    }
  }
})
</script>